import './App.css';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import { Cancel } from './components/Cancel';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import MoodIcon from '@mui/icons-material/Mood';
import { Redirect } from 'react-router-dom';




function App() {
  // let { id,ref } = useParams();
  // const regEx = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return (  
    <div className="App">
    <Router>
      <header>
        <div className='logo'>
          e.surv & WFS Chartered Surveyors 
        </div>
        <div className='contact'>
          <LocalPhoneIcon sx={{
            float:'left',
            fontSize:'20px',
            margin:'10px 5px 0 0'
          }}/> 01536 534123
        </div>
        <div class="clear-fix"></div>
      </header>
      <div>
        <Switch>
          <Route path='/jobId=:id&ref=:ref'>
            {/* {(regEx.test(id)&& /^\d+$/.test(ref)) ? <Redirect to="/*" /> : <Cancel/>} */}
            <Cancel/>
          </Route>
          <Route path='*'>
            <Card >
              <div className='errorCard'>
                <SentimentVeryDissatisfiedIcon sx={{width:'100%',fontSize:'100px',paddingTop:'1.5rem',color:'rgba(0,0,0,.54)'}}/>
                <Typography variant='h4'className='notFoundText'>Sorry there has been a problem, please call us to book your appointment.</Typography>
              </div>
            </Card>
          </Route>
          
        </Switch>
      </div>
    </Router>
    </div>

  );
}

export default App;
